/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    /* background: #888; */
    border-radius: 10px;
}

/* Custom scrollbar for Firefox */
* {
    scrollbar-width: thin;
    /* scrollbar-color: #888 #f1f1f1; */
}

.days_container {
    direction: rtl;
}

.days_content {
    direction:ltr;
}