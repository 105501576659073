/* filepath: /home/dominik-brandys/project/superviser/supervizer_v2/web_v2/src/components/employee/EmployeesHorizontalList.css */
.scroll-container {
    overflow-x: hidden;
    position: relative;
}

.scroll-content {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    min-height: 136px;
}

.scroll-content::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera */
}

.scroll-button {
    position: absolute !important;
    top: 50%;
    z-index: 1;
}

.scroll-button.left {
    left: 0;
}

.scroll-button.right {
    right: 0;
}

.scroll-button.right {
    right: 0;
}